<template>
    <div class="subs-header">
        <h1 class="subs-title center">Want delete Kangaroo Account?</h1>
    </div>
</template>

<script>
export default {
    name: "DeleteAccountHeader"
};
</script>

