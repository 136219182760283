import { isPhoneValid, formatPhoneForAuth } from "@/utilities/valid";

export function formatUsNumber(number) {
    let x = number.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    number = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    return number;
}

export function cleanPhoneNumber(countryCode, phone) {
    return formatPhoneForAuth(countryCode, phone);
}

export function isPhoneNumberValid(countryCode, phone) { 
    return isPhoneValid(countryCode, phone);
}