<template>
    <div>
        <div class="w-container">
            <div class="div-block-8">
                <h1 class="heading-5">Explore our products</h1>
                <div class="div-block-9">
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "SuccessContent",
    components: {
    },
    data() {
        return {
        }
    }
};
</script>

<style>
</style>