<template>
    <div class="login-wrap">
        <div class="login-header-wrap">
            <h5 class="roo-h5">1 of 2 LOG IN</h5>
            <h1 class="roo-h1">What’s your phone number?</h1>
            <h4 class="roo-h4">We&#x27;ll send you a verification code to verify your identity.</h4>
            <Support />
        </div>

        <h6 class="roo-h6 red" v-if="feedback">{{ feedback }}</h6>
        <div class="login-text-container">
            <div class="form-block-2 w-form" >
                <vue-country-code @onSelect="onSelect" :preferredCountries="['us', 'ca']" :enableSearchField=true>
                </vue-country-code>
                <input
                    v-model="phone"
                    class="roo-phone-textfield w-input"
                    maxlength="256"
                    placeholder="Phone Number"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    required
                    @input="formatInput"
                    v-on:keyup.enter="authenticateUserPhoneNumber"
                />
            </div>
        </div>
        <div v-if="isConnecting">
            <Loading />
        </div>
        <div v-if="!isConnecting" class="login-btn-wrap">
            <a @click="authenticateUserPhoneNumber" class="roo-btn w-button">submit</a>
        </div>
        <div id="recaptcha-container"></div>
    </div>
</template>

<script>
import fbApp from "@/network/firebaseInit";
import { initCaptcha, authenticateUserWithPhone } from "@/network/auth";
import { Feedback } from "@/utilities/feedback";
import Loading from "@/components/utility/Loading";
import Support from "@/components/utility/Support";
import * as PhoneViewModel from "@/view-models/phoneLoginViewModel";

export default {
    name: "PhoneLogin",
    components: {
        Loading,
        Support
    },
    data() {
        return {
            countryCode: "1",
            phone: "",
            feedback: null,
            isConnecting: false,
            max: 4,
            attempts: 0,
            country: ''
        };
    },
    created() {
        fbApp;
        initCaptcha();
        if (this.$route.params.isAuthRedirect === true) {
            this.feedback = Feedback.sessionExpired;
        }
    },
    methods: {
        async authenticateUserPhoneNumber() {
            if (!PhoneViewModel.isPhoneNumberValid(this.countryCode, this.phone)) {
                this.feedback = Feedback.validPhone;
                return;
            }

            this.isConnecting = true;
            this.feedback = null;
            try {
                this.attempts += 1;
                const p = PhoneViewModel.cleanPhoneNumber(this.countryCode, this.phone);
                await authenticateUserWithPhone(p);
                this.successAuth();
            } catch (err) {
                console.log(err)
                this.failureAuth(err);
            }
        },
        successAuth() {
            this.isConnecting = false;
            this.feedback = null;
            this.$emit("authCodeDidSend", true);
        },
        failureAuth(err) {
            this.isConnecting = false;
            if (this.attempts > 6) {
                this.feedback = Feedback.contactSupport;
            } else {
                switch (err.codeType) {
                    case "auth/too-many-requests":
                        this.feedback = Feedback.tooManyRequests;
                        break;
                    default:
                        this.feedback = Feedback.validPhone;
                        break;
                }
            }
        },
        onSelect({ iso2, dialCode }) {
            if (this.countryCode !== dialCode) {
                this.phone = ""
            } 
            this.countryCode = dialCode
            this.country = iso2
        },
        formatInput() {
            if (this.country === 'US') {
                this.phone = PhoneViewModel.formatUsNumber(this.phone)
            }
        }

    },
};
</script>

<style>
.vue-country-select {
    margin-left: 10px;
    margin-right: 10px;
    color: black;
    font-size: 16px;
    font-family: 'Sofia pro';
    font-weight: normal;
    border-radius: 0px;
    border: 0px solid transparent !important;
}

.vue-country-select .search-field input {
    font-size: 14px;
    padding: 6px;
}

.vue-country-select .dropdown:hover {
    background-color: #0c1835 !important;
}

.vue-country-select .dropdown-arrow {
    padding-left: 4px;
} 

b, strong {
    font-weight: normal;
} 

@media screen and (max-width: 767px) {
    .login-text-container {
        height: auto;
    }
}
@media screen and (max-width: 479px) {
    .vue-country-select .dropdown-list {
        max-width: 300px;
    }
}
</style>