<template>
    <div>
        <SuccessHeader />
        <SuccessContent />
    </div>
</template>

<script>
import SuccessHeader from "@/components/success/SuccessHeader";
import SuccessContent from "@/components/success/SuccessContent";
import { updateUserEmail } from "@/network/user";

export default {
    name: "Success",
    components: {
        SuccessHeader,
        SuccessContent
    },
    created() {
        window.scrollTo(0, 0);
        if (this.$route.params.email) {
            updateUserEmail(this.$route.params.email);
        }
    },
    destroyed() {
        localStorage.clear();
    }
};
</script>