<template>
    <transition name="fade">
        <div class="modal" v-if="show">
            <div class="modal__backdrop" />
            <div class="modal__dialog">
                <div class="modal__header">
                    <div class="modal-header-wrap">
                        <div class="modal-btn-wrap" style="display: none;">
                            <a @click="closeModal" class="w-inline-block">
                                <img src="@/assets/close-icon.png" alt />
                            </a>
                        </div>
                        <div class="modal-header">
                            <h1 class="modal-title">Delete Account</h1>
                        </div>
                    </div>
                </div>

                <div class="modal__body">
                    <div style="text-align: center;padding: 20px;font-size:16px">
                       Are you sure? This action is permanent and cannot be reverted.
                    </div>
                </div>

                <div class="modal__footer">
                    <button :disabled='isDisabled' class="confirm-delete" @click="deleteAccount">DELETE</button>
                    <button class="confirm-cancel" @click="closeModal">CANCEL</button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    name: "ConfirmModal",
    components: {
    },
    data() {
        return {
            show: false,
            isDisabled: false
        };
    },
    methods: {
        closeModal() {
            this.show = false;
            document.querySelector("body").classList.remove("overflow-hidden");
        },
        deleteAccount() {
           this.isDisabled = true;
           this.parent.onAccountDelete();

        },
        openModal(parent) {
            this.parent = parent;
            this.show = true;
            this.isDisabled = false;
            document.querySelector("body").classList.add("overflow-hidden");
        }
    }
};
</script>