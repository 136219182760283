import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAnalytics from 'vue-analytics';
import VueCountryCode from "vue-country-code-select";

let id;
if (process.env.VUE_APP_ENV === "production") {
    id = 'UA-115492590-7'
} else {
    id = 'UA-115492590-9'
}

Vue.use(VueAnalytics, {
    id: id,
    router
})
Vue.use(VueCountryCode)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
