<template>
    <div class="success-header-section">
        <div class="w-container">
            <div class="success-header">
                <img src="@/assets/armed-light.png" class="home-shield-img" />
                <h1 class="roo-title">Your Kangaroo Plan is activated.</h1>
                <div class="max-wrap">
                    <h4 class="roo-subtitle">Your plan is set to renew on {{ renewDate }}. Use the Kangaroo Security app to manage your plan.</h4>
                </div>
                <!-- <div class="success-btn-wrap">
                    <a href="#" class="roo-btn-clear w-button">OPEN KANGAROO APP</a>
                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
import { formatUnixRenewDate } from "@/utilities/formatter";

export default {
    name: "SuccessHeader",
    computed: {
        renewDate() {
            if (this.$route.params.renewDate) {
                return formatUnixRenewDate(this.$route.params.renewDate);
            } else {
                return "";
            }
        },
        url() {
            return "";
        }
    },
    created() {
    }
};
</script>
