<template>
    <div>
        <a @click="resendCode" class="link-block w-inline-block">
            <h3 class="resend-h3" v-bind:class="{'white': !isReattempt }">{{ resendTitle }} {{ countDownTitle }}</h3>
        </a>
    </div>
</template>

<script>
import { authenticateUserWithPhone } from "@/network/auth";
import { getUserPhone } from "@/utilities/storage";

export default {
    name: "ResendCode",
    data() {
        return {
            resendTitle: "Resend the code",
            isReattempt: false,
            countDown: 60,
            canResend: false,
            countDownTitle: "",
        };
    },
    watch: {
        countDown: {
            handler(value) {
                if (value > 0) {
                    this.countDownTitle = `(${this.countDown})`;
                    this.canResend = false;
                    setTimeout(() => {
                        this.countDown--;
                    }, 1000);
                } else {
                    this.canResend = true;
                    this.countDownTitle = "";
                }
            },
            immediate: true,
        },
    },
    methods: {
        async resendCode() {
            if (this.canResend) {
                try {
                    this.isReattempt = true;
                    this.resendTitle = "Sending Code";
                    await authenticateUserWithPhone(getUserPhone());
                    this.successAuth();
                } catch (err) {
                    this.failureAuth(err);
                }
            }
        },
        successAuth() {
            this.resendTitle = "Code sent";
            setTimeout(() => {
                this.isReattempt = false;
                this.resendTitle = "Resend the code";
                this.countDown = 60;
            }, 3000);
        },
        failureAuth() {
            this.isReattempt = false;
            this.resendTitle = "Resend the code";
            this.countDown = 60;
        },
    },
};
</script>

<style scoped>
.link-block {
    cursor: pointer;
}
.white {
    color: white;
}
</style>