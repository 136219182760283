import firebase from 'firebase/compat/app'
import 'firebase/compat/auth';   

import {
    initializeAppCheck,
    ReCaptchaV3Provider
  } from "firebase/app-check";

const configOptions = {
    apiKey: "AIzaSyCGkTKrI3T08x9uP1PRdMsm5svJgFus4d8",
    authDomain: "auraspace-f126f.firebaseapp.com",
    databaseURL: "https://auraspace-f126f.firebaseio.com",
    projectId: "auraspace-f126f",
    appId: "1:885913402024:web:0d2173fe7375f3df39a09d",
    siteKey: "6LfqHvMpAAAAALoQQZ8GbCLl8FtsQLo54TKB9bJd"
};

const firebaseApp = firebase.initializeApp(configOptions);
firebase.getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        return firebase.auth().onAuthStateChanged(user => {
            resolve(user);
        }, reject);
    })
};

initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(configOptions.siteKey),
    isTokenAutoRefreshEnabled: true
 });
 

export default firebaseApp