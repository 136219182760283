<template>
    <div>
        <div id="roo-nav" class="nav">
            <div class="nav-container">
                <div class="nav-img-wrap">
                    <img @click="goHome" src="@/assets/blacklogo.svg" alt class="nav-img" />
                </div>
                <div class="nav-link-wrap" style="display: none;">
                    <a class="nav-link">What are Kangaroo Plans?</a>
                    <a @click="openFaq" class="nav-link">FAQ</a>
                </div>
            </div>
        </div>
        <div id="roo-nav-pad" class="nav-padding"></div>
    </div>
</template>

<script>

export default {
    name: "Nav",
    data() {
        return {
            showFaq: false
        }
    },
    components: {
    },
    methods: {
        goHome() {
            this.$router.push({name: "home"})
        },
        openFaq() {
        }
    }
};
</script>
<style>
.nav-link {
    cursor: pointer;
}
</style>