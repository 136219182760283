import { parsePhoneNumberFromString } from 'libphonenumber-js';
import moment from 'moment';

export function displayPhoneNumber(phone) {
    const phoneNumber = parsePhoneNumberFromString(phone);
    if (phoneNumber.countryCallingCode === "1") {
        return phoneNumber.formatNational()
    } else {
        return phoneNumber.formatInternational()
    }
}

export function formatRenewDate(type) {
    if (type === 0) {
        const month = moment().add(1, 'months');
        return month.format('ll')
    } else {
        const year = moment().add(1, 'years');
        return year.format('ll')
    }
}

export function formatUnixRenewDate(date) {
    return moment.unix(date).format('ll'); 
}

export function billingCycle(subscription) {
    if (subscription) {
        return subscription.usePlanInterval === 0 ? formatMonthlyCillingCycle(subscription) : formatYearlyCillingCycle(subscription)
    } else {
        return ""
    }
}

function formatYearlyCillingCycle(subscription) {
    return `Annual ($${subscription.price}/yr)`
}

function formatMonthlyCillingCycle(subscription) {
    return `Monthly ($${subscription.price}/mo)`
}