<template>
    <div class="login-section">
        <div class="login-wrap">
            <div class="login-header-wrap">
                <h1 class="roo-h1">Page Not Found</h1>
                <h4 class="roo-h4">Hop back home</h4>
            </div>
            <div class="login-btn-wrap">
                <a @click="home" class="roo-btn w-button">Home</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        home() {
            this.$router.push({ name: "home" })
        }
    }
};
</script>

<style>
</style>