import axios from "axios";
import { getUserToken, saveUserDetails } from '@/utilities/storage';
import { RooLogger } from "@/utilities/RooLogger";
const userUrl = `${process.env.VUE_APP_BASE_URL}/user`;
const checkUserUrl = `${process.env.VUE_APP_BASE_URL}/user/check`;

export async function getUserData() {
    try {
        const user = await axios(configureLoginRequest());
        saveUserDetails(user.data);
        return Promise.resolve(user.data);
    } catch (err) {
        const e = new Error("Can not get user data");
        e.status = err.response.status || 500;
        e.msg = err.message;
        e.res = err.response.data
        e.codeType = "failure/user-data"
        RooLogger.error("Getting User Data", e, {})
        return Promise.reject(e);
    }
}

export async function checkUser() {
    try {
        const result = await axios(configureCheckUserRequest());
        return Promise.resolve(result.data);
    } catch (err) {
        const e = new Error("Can not update user data");
        e.status = err.response.status || 500;
        e.msg = err.message;
        e.res = err.response.data
        return Promise.reject(new Error(""));
    }
}


export async function deleteAccount() {
    try {
        await axios(configureDeleteAccountRequest());
        return Promise.resolve(true);
    } catch (err) {
        const e = new Error("Can not delete account");
        e.status = err.response.status || 500;
        e.msg = err.message;
        e.res = err.response.data
        // RooLogger.error("Delete User Account", e, { email: email })
        return Promise.reject(new Error(""));
    }
}

function configureLoginRequest() {
    const config = {
        method: "get",
        url: userUrl,
        headers: {
            'Authorization': getUserToken(),
            'Content-Type': 'application/json',
        }
    }
    return config;
}

function configureCheckUserRequest() {
    const config = {
        method: "get",
        url: checkUserUrl,
        headers: {
            'Authorization': getUserToken(),
            'Content-Type': 'application/json',
        }
    }
    return config;
}

function configureDeleteAccountRequest() {
    const config = {
        method: "delete",
        url: userUrl,
        headers: {
            'Authorization': getUserToken(),
            'Content-Type': 'application/json',
        },
        data: {
        }
    }
    return config;
}