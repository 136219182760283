<template>
    <div class="selection-card">
        <Button @click="selection" class="delete-button">Delete My Account</Button>
    </div>
</template>

<script>
export default {
    name: "DeleteAccountCard",
    props: ["title", "subTitle", "selectionValue"],
    methods: {
        selection() {
            this.$emit(this.selectionValue);
        }
    }
};
</script>

<style>
.link-box {
    cursor: pointer;
}
</style>