import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login'
import Success from '@/views/Success'
import DeleteAccount from '@/views/DeleteAccount'
import NotFound from '@/views/NotFound'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth';

Vue.use(VueRouter)

const router = new VueRouter({
    mode: "history",
    routes: [{
            path: '/',
            name: 'login',
            component: Login
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/deleteAccount',
            name: 'deleteAccount',
			component: DeleteAccount,
			meta: {
                requiresAuth: true
            }
        },
        {
            path: '/success',
            name: 'success',
            component: Success,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "*",
            name: "notFound",
            component: NotFound
        }
    ]
})

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(rec => rec.meta.requiresAuth)) {
        let user = await firebase.getCurrentUser()
        if (user) {
            next()
        } else {
            next({
                name: 'login'
            })
        }
    } else {
        next()
    }
})

export default router