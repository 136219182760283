<template>
    <div class="support-wrap">
        <h5 class="support-label" v-bind:class="{'blk': isLightMode }">Having trouble? Delete account with a GuRoo</h5>
        <h5 class="support-label">
            <a target="_blank" href="tel:+18884575439" class="support-link">+1 888-457-5439</a>
        </h5>
    </div>
</template>

<script>

export default {
    name: "Support",
    props: ["isLightMode"],
    methods: {
       
    }
};
</script>
