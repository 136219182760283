export function saveUserToken(token) {
    if(token) {
        localStorage.token = token;
    }
}

export function getUserToken() {
    return localStorage.token || null;
}

export function saveUserDetails(user) {
    if(user) {
        localStorage.setItem('user', JSON.stringify(user));
    }
}

export function getUserDetails() {
    return JSON.parse(localStorage.getItem('user'));
}

export function saveUserPhone(phone) {
    if (phone) {
        localStorage.phone = phone;
    }
}

export function getUserPhone() {
    return localStorage.phone || null;
}

export function saveAuthCode(code) {
    if(code) {
        localStorage.authCode = code;
    }
}

export function getAuthCode() {
    return localStorage.authCode || null;
}

export function saveUserNotFound(userNotFound) {
    localStorage.userNotFound = userNotFound;
}

export function getUserNotFound() {
    return localStorage.userNotFound;
}

export function isPPP() {
	if (localStorage.subscription) {
		const sub = JSON.parse(localStorage.getItem('subscription'));
		const isPPP = sub.usePlanType === 2 ? true : false;
		return isPPP;
	} else if (localStorage.providerSubscription) {
		const sub = JSON.parse(localStorage.getItem('providerSubscription'));
		const isPPP = sub.subType === "ppp" ? true : false;
		return isPPP;
	} else {
		return false;
	}
}

export function removeItem(item) {
	localStorage.removeItem(item);
}