export class Feedback {
    static validPhone = "Please enter a valid phone number";
    static validVerification = "Please enter a valid verifcation number";
    static validActivationCode = "Please enter a valid activation code";
    static codeCantBeApplied = "You have already redeemed a similar Kangaroo offer in the past.";
    static validCreditCard = "Please enter a valid credit card.";
    static contactSupport = "There was an error processing your request. Please contact customer support at +1 888-457-5439";
    static validEmail = "Please enter a valid email address.";
    static sessionExpired = "Your session has timed out. Please log in again.";
    static codeDoesNotExist = "Please enter a valid Activation Code and make sure you are not entering the verification number from the previous step.";
    static isAuthCode = "Looks like you entered the verification number from the last step, please enter your Activation Code instead.";
    static paymentFail = "There was an issue with your payment. Please try another method.";
    static authCodeExpired = "The verification code has expired. Please tap the resend code button to recieve a new one.";
    static tooManyRequests = "You have tried too many times. Please try again later.";
    static codeExpired = "This Activation Code has expired.";
    static guestRestriction = "Only homeowners are allowed to activate a Kangaroo Plan.";
    static codeAlreadyUsed = "This Activation Code has already been used.";
    static pairDeviceFirst = "You must pair your device with the Kangaroo mobile app first before you can activate your pre-paid plan.";
    static userAlreadyActivated = "It looks like you may have already activated before.";
}