<template>
    <div class="login-wrap">
        <div class="login-header-wrap">
            <h5 class="roo-h5">1 of 2 LOG IN</h5>
            <h1 class="roo-h1">Enter verification number</h1>
            <h4 class="roo-h4">Verify your identity by entering the verification number sent to {{ phone }}</h4>
            <Support/>
        </div>
        <h6 class="roo-h6 red" v-if="feedback">{{ feedback }}</h6>
        <input
            v-model="authCode"
            type="number"
            class="roo-num-textfield w-input"
            v-bind:class="{'red-border': feedback }"
            maxlength="256"
            inputmode="numeric"
            pattern="[0-9]*"
            input
            id="single-factor-code-text-field"
            autocomplete="one-time-code"
            v-on:keyup.enter="verifyAuthCode"
            placeholder="Verification Number"/>

        <div v-if="isConnecting"><Loading /></div>
        <ResendCode v-if="!isConnecting"/>
        <div v-if="!isConnecting" class="login-btn-wrap">
            <a @click="verifyAuthCode" class="roo-btn w-button">submit</a>
        </div>
    </div>
</template>

<script>
import fbApp from "@/network/firebaseInit";
import { verifyAuthCode } from "@/network/auth"; 
import { checkUser } from "@/network/user";
import * as Storage from "@/utilities/storage";
import { displayPhoneNumber } from "@/utilities/formatter";
import { isVerifcationCodeValid } from "@/utilities/valid";
import { Feedback } from "@/utilities/feedback";
import Loading from "@/components/utility/Loading"
import Support from "@/components/utility/Support";
import ResendCode from "@/components/login/ResendCode"

export default {
    name: "LoginVerificationCode",
    components: {
        ResendCode,
        Support,
        Loading
    },
    data() {
        return {
            authCode: "",
            feedback: null,
            isConnecting: false
        };
    },
    computed: {
        phone() {
            return displayPhoneNumber(Storage.getUserPhone());
        }
    },
    created() {
        fbApp;
        //initCaptcha();
    },
    methods: {
        async verifyAuthCode() {
            if (!this.isCodeValid()) {
                return;
            }
            this.isConnecting = true;
            this.feedback = null;
            try {
                await verifyAuthCode(this.authCode);
                this.successVerifyCode();
            } catch(err) {
                this.failureToVerifyCode(err);
            }
        },
        isCodeValid() {
            if (!isVerifcationCodeValid(this.authCode)) {
                this.feedback = Feedback.validVerification;
                return false;
            } else {
                return true;
            }
        },
        async successVerifyCode() {
            let userExist = await checkUser();
            if (userExist.exist == false) {
                //this.isConnecting = false;
                //this.feedback = null;
                //this.$emit("authCodeDidSend", false);
                //return;
                Storage.saveUserNotFound(true);
            } else {
                Storage.saveUserNotFound(false);
            }

            Storage.saveAuthCode(this.authCode);
            this.isConnecting = false;
            this.feedback = null;
            this.handleUserRouting();
        },
        failureToVerifyCode(err) {
            this.isConnecting = false;
            switch (err.codeType) {
                case "auth/code-expired":
                    this.feedback = Feedback.authCodeExpired;
                    break;
                case "auth/too-many-requests":
                    this.feedback = Feedback.tooManyRequests;
                    break;
                case "failure/user-data":
                    this.feedback = Feedback.contactSupport;
                    break;
                default:
                    this.feedback = Feedback.validVerification;
            }
            
        },
        handleUserRouting() {
            this.$router.push({ name: "deleteAccount" });
        }
    }
};
</script>