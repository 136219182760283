<template>
    <div class="login-section">
        <div>
            <keep-alive>
                <PhoneLogin v-on:authCodeDidSend="authCodeWasSent" v-bind:is="currentStage" />
                <LoginVerificationCode v-on:authCodeDidSend="authCodeWasSent" v-bind:is="currentStage" />
            </keep-alive>
        </div>
    </div>
</template>

<script>
import PhoneLogin from "@/components/login/PhoneLogin";
import LoginVerificationCode from "@/components/login/LoginVerificationCode";

export default {
    name: "Login",
    components: {
        PhoneLogin,
        LoginVerificationCode
    },
    data() {
        return {
            currentStage: "PhoneLogin"
        };
    },
    created() {
       
    },
    methods: {
        authCodeWasSent(didSucceed) {
            if (didSucceed) {
                this.currentStage = "LoginVerificationCode";
            } else {
                alert("User not found");
                this.currentStage = "PhoneLogin";
                window.location.reload();
            }
        }
    }
};
</script>
