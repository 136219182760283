<template>
    <div class="loading-wrap">
        <lottie
            :options="defaultOptions"
            :height="100"
            :width="120"
            v-on:animCreated="handleAnimation"
        />
    </div>
</template>

<script>
import Lottie from "vue-lottie";
import * as animationData from "@/assets/jumping-roo.json";

export default {
    name: "Loading",
    components: {
        Lottie
    },
    data() {
        return {
            defaultOptions: { animationData: animationData.default },
            animationSpeed: 1
        };
    },
    methods: {
        handleAnimation: function(anim) {
            this.anim = anim;
        },
        stop() {
            this.anim.stop();
        }
    }
};
</script>

<style>
</style>