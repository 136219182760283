import pino from 'pino'
import { createPinoBrowserSend } from 'pino-logflare'
import moment from 'moment'
import { getUserDetails, getUserPhone } from '@/utilities/storage'
const key = process.env.VUE_APP_LOG_KEY
const tok = process.env.VUE_APP_LOG_TOKEN

const send = createPinoBrowserSend({
    apiKey: key,
    sourceToken: tok
});

const logger = pino({
    browser: {
        transmit: {
            send: send
        }
    }
});

export class RooLogger {

    static log(title, data) {
        const t = `$- SUCCESS | ${title} |`
        const d = configureLog(data)
        logger.info(t, JSON.stringify(d));
    }

    static error(title, error, data) {
        let t = `!- FAILURE | ${title} | `
        const d = configureLog(data, error)
        logger.info(t, JSON.stringify(d));
    }
}

function logTimestamp() {
    return moment().unix();
}

function prettyTime() {
    return moment().format('MM-DD-YYYY HH:MM');
}

function configureLog(data, error) {
    const d = {}
    d.data = data;
    d.user = getUserDetails() || null;
    if (d.user === null) { d.phone = getUserPhone(); }
    if (error) { d.error = error; }
    d.app = { name: "activate", env: process.env.VUE_APP_ENV, version: process.env.VUE_APP_VERSION }
    d.timestamp = logTimestamp();
    d.prettyTime = prettyTime();
    return d;
}