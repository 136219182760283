export function isPhoneValid(countryCode, phone) {
    const c = stripSpaceAndChars(countryCode);
    const isCodeValid = isNotEmpty(countryCode) && isNumber(c);
    const p = stripSpaceAndChars(phone);
    const isPhoneValid = isNotEmpty(countryCode) && isNumber(p);
    return isCodeValid && isPhoneValid;
}

export function formatPhoneForAuth(countryCode, phone) {
    const code = stripSpaceAndChars(countryCode);
    const p = stripSpaceAndChars(phone);
    return `+${code}${p}`
}

export function isVerifcationCodeValid(code) {
    return isNotEmpty(code); 
}

export function isValidEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function stripSpaceAndChars(str) {
    let string = str.replace(/[^\w\s]/gi, '');
    return string.split(" ").join("");
}

function isNumber(str) {
    return /^\d+$/.test(str);
}

function isNotEmpty(str) {
    return str.length > 0;
}
