import {
    RecaptchaVerifier,
    getAuth,
    signInWithPhoneNumber
} from 'firebase/auth';
import { saveUserToken, saveUserPhone } from '@/utilities/storage';
import { RooLogger } from "@/utilities/RooLogger"

export function initCaptcha() {
    setTimeout(function () {
        const auth = getAuth();
        window.recaptchaVerifier = new RecaptchaVerifier(auth,
            "recaptcha-container", {
                size: "visible",
                callback: function (response) {
                    console.log(response);
                }
            }
        );
    }, 500);
}

export async function authenticateUserWithPhone(phone) {
    const auth = getAuth();
    if (window.recaptchaVerifier) {
        try {
            const appVerifier = window.recaptchaVerifier;
            const result = await signInWithPhoneNumber(auth, phone, appVerifier);
            window.confirmationResult = result;
            saveUserPhone(phone);
            return Promise.resolve(true);
        } catch (error) {
            RooLogger.error("Phone Login", error, { phone: phone })
            const e = new Error(error)
            e.codeType = error.code
            return Promise.reject(e);
        }
    } else {
        const e = new Error("Something went wrong, refresh and try again")
        RooLogger.error("Phone Window Captcha", e, { phone: phone })
        return Promise.reject(e);
    }
}

export async function verifyAuthCode(code) {
    try {
        await window.confirmationResult.confirm(code);
        await saveUserAccessToken();
        return Promise.resolve(true)
    } catch (err) {
        RooLogger.error("Phone Verify Code", err, { verifyCode: code })
        const e = new Error(err)
        e.codeType = err.code
        return Promise.reject(e);
    }
}

async function saveUserAccessToken() {
    const auth = getAuth();
   
    try {
        const token = await auth.currentUser.getIdToken();
        saveUserToken(token);
        return Promise.resolve(true);
    } catch (err) {
        return Promise.reject(null);
    }
}