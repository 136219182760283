<template>
    <div id="app">
        <Navbar/>
        <router-view />
    </div>
</template>

<script>
import Navbar from "@/components/navigation/Navbar";
export default {
    components: {
        Navbar
    },
    data() {
        return {
            title: process.env.VUE_APP_TITLE
        };
    }
};
</script>
<style src="../public/css/normalize.css"></style>
<style src="../public/css/webflow.css"></style>
<style src="../public/css/kangaroo.css"></style>
<style src="../public/css/activate.css"></style>
<style src="../public/css/modal.css"></style>

