import * as Storage from "@/utilities/storage";

export const selectPlan = {
    title: "I want to pick a Kangaroo plan",
    val: "plan"
}

export const code = {
    title: "I have an Activation Code",
    sub: "Find in your email, SMS or on a Kangaroo Complete card.",
    val: "code"
}

export function didSelectCode() {
	Storage.saveIsSubscriptionPurchase(false);
	Storage.removeItem('providerSubscription');
}

export function didSelectPurchase() {
	Storage.removeItem('subscription');
}

export function didAccountRemove() {
	Storage.removeItem('token');
}