<template>
    <div>
        <div class="activate-section-white">
            <div class="w-container">
                <DeleteAccountHeader/>
                <div ref="deleteButtonContainer" class="selection-wrap">
                    <DeleteAccountCard v-if="showDeleteAccountCard" v-on:plan="deleteConfirmDialog" :title="vm.selectPlan.title" :selectionValue="vm.selectPlan.val"/>
                </div>

                <div style="color:red;align-self:flex-start;text-align:center;font-size:28px" id="deleteMessageContainer" 
                    ref="deleteMessageContainer">{{ deleteMessage }}</div>
            </div>
        </div>
       
        <ConfirmModal ref="confirmModal"/>
    </div>
</template>

<script>
import DeleteAccountHeader from "@/components/select/DeleteAccountHeader";
import DeleteAccountCard from "@/components/select/DeleteAccountCard";
import * as DeleteAccountViewModel from "@/view-models/deleteAccountViewModel";
import ConfirmModal from "@/components/utility/ConfirmModal"
import { deleteAccount } from "@/network/user";
import { getUserNotFound } from "@/utilities/storage";



export default {
    name: "DeleteAccountSelection",
    components: {
        DeleteAccountHeader,
        DeleteAccountCard,
        ConfirmModal
    },
    data() {

        return {
            vm: DeleteAccountViewModel,
            showDeleteAccountCard: getUserNotFound() == "false"
        }
    },
    methods: {
        deleteConfirmDialog() {
            this.$refs.confirmModal.openModal(this)
        },
        async onAccountDelete() {
            await deleteAccount();
            this.vm.didAccountRemove();
            this.$refs.confirmModal.closeModal(this);
            this.showDeleteAccountCard = false;
            this.$refs.deleteMessageContainer.innerText = "Account deleted successfully!"
        }
    },
    computed: {
        deleteMessage() {
            var message = "";
            let isUserNotFound = getUserNotFound();
            if (isUserNotFound == "true") {
                
                message = "User Not Found!"
            }

            return message;
        }
    }
};
</script>
